@import "reset.scss";
@import "setting/_fonts.scss";
@import "setting/_mixins.scss";
@import "setting/_options.scss";


body {
    font-family: $font;
    font-size: $fontsize;
    line-height: 145%;
    background: #080617;
    color: #fff;
}

.main {
    width: 100%;
    max-width: 590px;
    margin: 0 auto;
}

@import "blocks/_banner.scss";
@import "blocks/_footer.scss";
@import "blocks/_form.scss";
@import "blocks/_grid.scss";
@import "blocks/_header.scss";
@import "blocks/_info.scss";