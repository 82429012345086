.header {
    width: 100%;
    max-width: 590px;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 32px;

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__logo {
        img {
            max-width: 200px;
            max-height: 38px;
            height: auto;
            object-fit: contain;
        }
    }

    @media (max-width: 768px) {
        padding: 24px;
    }
}