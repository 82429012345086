.info {
  padding: 75px 0 40px;

  &__card {
    border-radius: 10px;
    border: 1px solid #ACA5E1;
    background: radial-gradient(91.41% 81.02% at 50% 133.8%, rgba(255, 255, 255, 0.19) 0%, rgba(8, 6, 23, 0.15) 100%);
    backdrop-filter: blur(18px);
    width: 100%;
    min-width: 137px;
    height: 100%;
    max-height: 115px;
    padding: 16px 20px 16px 176px;
    position: relative;

    @media (max-width: 768px) {
      max-height: 100%;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__title {
    font-weight: 600;
    letter-spacing: 0.07px;
  }

  &__link {
    font-family: $font;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.065px;
    color: #A2FACF;

    &:hover {
      color: #624bed;
    }
  }

  &__image {
    position: absolute;
    left: 0;
    top: -16px;
  }

  @media (max-width: 768px) {
    padding: 75px 24px 0;
  }
}