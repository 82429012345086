@charset "UTF-8";
/* 
---------------------------------------------
reset
--------------------------------------------- 
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, div pre,
a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q,
s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li,
figure, header, nav, section, article, aside, footer, figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

ul, li {
  padding: 0;
  margin: 0;
  list-style: none;
}

header, nav, section, article, aside, footer, hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}

html, body {
  background-color: #fff;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0px;
  margin-bottom: 0px;
}

ul {
  margin-bottom: 0px;
}

h2,
.h2 {
  color: #FFF;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 145%;
  background: #080617;
  color: #fff;
}

.main {
  width: 100%;
  max-width: 590px;
  margin: 0 auto;
}

a {
  transition: all 0.4s ease;
}

button {
  cursor: pointer;
  transition: all 0.4s ease;
}

.banner__row {
  display: flex;
  flex-direction: column;
  gap: 43px;
}
.banner__title {
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}
.banner__title a,
.banner__title span {
  color: #624BED;
  border-bottom: 1px solid;
  transition: 0.3s;
}
.banner__title a:hover,
.banner__title span:hover {
  border-color: transparent;
}
@media (max-width: 768px) {
  .banner__title {
    padding: 0 24px;
  }
}
@media (max-width: 768px) {
  .banner__form_w {
    padding: 0 24px;
  }
}
.banner__phone {
  text-align: center;
  margin-bottom: -8px;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  height: 465px;
  transform: scale(0.7);
  transform-origin: center bottom;
  margin-top: calc(40px - 30%);
}
@media (max-width: 768px) {
  .banner__phone {
    margin-top: -30%;
  }
}
.banner__phone:after {
  content: "";
  width: 313px;
  height: 313px;
  border-radius: 313px;
  opacity: 0.25;
  background: #8A88F6;
  filter: blur(50px);
  position: absolute;
  top: 76px;
  left: 23.5%;
  z-index: 0;
  display: block;
}
.banner__phone img {
  width: 350px;
}
.banner__block {
  display: flex;
  flex-direction: column;
  gap: 0;
}
.banner__block__link {
  border-radius: 60px;
  border: 1px solid #624BED;
  background: #5137EB;
  box-shadow: 0px 2px 6px 0px rgba(19, 4, 105, 0.3);
  width: 100%;
  max-width: 220px;
  padding-right: 24px;
  margin: 0 auto;
  position: absolute;
  z-index: 2;
  top: -19px;
  min-height: 40px;
  max-height: 42px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  transition: all 0.4s ease;
}
.banner__block__link a {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 10;
}
.banner__block__link .svg_arr {
  transition: all 0.4s ease;
}
.banner__block__link:hover {
  border-color: #624BED;
  background: #624BED;
}
.banner__block__link:hover .svg_arr {
  transform: rotate(45deg);
}
.banner__block__link__text {
  line-height: 1;
}
.banner__block__link__wrap {
  display: flex;
  justify-content: center;
  width: 100%;
}
.banner__block__link__img {
  position: absolute;
  left: -1px;
  top: -1px;
}
.banner__block__link__img svg {
  box-shadow: 0px 7.143px 27.143px 0px rgba(38, 24, 121, 0.72);
  border-radius: 99px;
}
.banner__block__wrapper {
  border-radius: 23px;
  border: 1px solid rgba(255, 255, 255, 0.13);
  background: #0D0A22;
  padding: 48px 24px 32px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  position: relative;
  z-index: 2;
}
.banner__block__text p {
  margin-bottom: 12px;
}
.banner__block__text__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.banner__block .form__btn__tel {
  max-width: 444px;
}

.banner__phone_wr {
  position: relative;
  max-height: 455px;
  overflow: hidden;
}

.iframe-container {
  border-radius: 48px;
  display: block;
  position: absolute;
  width: 350px;
  height: 803px;
  z-index: 100;
  overflow: hidden;
  padding: 0 17px;
  top: 50px;
}
.iframe-container::before {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  margin: 0 17px;
  height: 30px;
  background: transparent;
  background: -moz-linear-gradient(180deg, rgb(234, 235, 247) 0%, rgba(234, 235, 247, 0) 100%);
  background: -webkit-linear-gradient(180deg, rgb(234, 235, 247) 0%, rgba(234, 235, 247, 0) 100%);
  background: linear-gradient(180deg, rgb(234, 235, 247) 0%, rgba(234, 235, 247, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eaebf7",endColorstr="#eaebf7",GradientType=1);
}

.footer {
  position: relative;
}
.footer__image {
  width: 100%;
  max-height: 557px;
  overflow: hidden;
}
@media (max-width: 768px) {
  .footer__image {
    position: relative;
    height: 240px;
  }
}
.footer__image img {
  width: 100%;
  object-fit: contain;
}
@media (max-width: 768px) {
  .footer__image img {
    object-fit: cover;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
  }
}
.footer__logo {
  text-align: center;
  position: absolute;
  bottom: 46px;
  width: 100%;
  z-index: 10;
}

.form {
  width: 100%;
}
.form__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
}
.form__btn {
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  gap: 10px;
  color: #FFF;
  width: fit-content;
}
.form__btn:hover {
  background: #624BED;
}
.form__btn svg {
  max-width: 16px;
  max-height: 16px;
}
.form__btn__tel {
  width: calc(100% - 96px);
}
.form__btn_main {
  border: 1px solid #624BED;
  background: #5137EB;
  box-shadow: 0 2px 6px 0 rgba(19, 4, 105, 0.3);
  position: absolute;
  top: 0;
  right: 0;
  min-width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0;
  height: 100%;
}
.form__btn_main.is-active {
  min-width: 136px;
}
.form__btn_main.is-active:after {
  content: "";
  position: relative;
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImNoZXZyb24tcmlnaHQiPgo8cGF0aCBpZD0iVmVjdG9yIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTguMjkyODkgNS4yOTI4OUM4LjY4MzQyIDQuOTAyMzcgOS4zMTY1OCA0LjkwMjM3IDkuNzA3MTEgNS4yOTI4OUwxNS43MDcxIDExLjI5MjlDMTYuMDk3NiAxMS42ODM0IDE2LjA5NzYgMTIuMzE2NiAxNS43MDcxIDEyLjcwNzFMOS43MDcxMSAxOC43MDcxQzkuMzE2NTggMTkuMDk3NiA4LjY4MzQyIDE5LjA5NzYgOC4yOTI4OSAxOC43MDcxQzcuOTAyMzcgMTguMzE2NiA3LjkwMjM3IDE3LjY4MzQgOC4yOTI4OSAxNy4yOTI5TDEzLjU4NTggMTJMOC4yOTI4OSA2LjcwNzExQzcuOTAyMzcgNi4zMTY1OCA3LjkwMjM3IDUuNjgzNDIgOC4yOTI4OSA1LjI5Mjg5WiIgZmlsbD0id2hpdGUiLz4KPC9nPgo8L3N2Zz4K");
  background-size: contain;
}
.form__btn_main.download {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form__btn_main.download:after {
  content: "";
  position: relative;
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI0IDEyQzI0IDE4LjYyNzQgMTguNjI3NCAyNCAxMiAyNEM1LjM3MjU4IDI0IDAgMTguNjI3NCAwIDEyQzAgNS4zNzI1OCA1LjM3MjU4IDAgMTIgMEMxOC42Mjc0IDAgMjQgNS4zNzI1OCAyNCAxMlpNMi40IDEyQzIuNCAxNy4zMDE5IDYuNjk4MDcgMjEuNiAxMiAyMS42QzE3LjMwMTkgMjEuNiAyMS42IDE3LjMwMTkgMjEuNiAxMkMyMS42IDYuNjk4MDcgMTcuMzAxOSAyLjQgMTIgMi40QzYuNjk4MDcgMi40IDIuNCA2LjY5ODA3IDIuNCAxMloiIGZpbGw9InVybCgjcGFpbnQwX2FuZ3VsYXJfMzMzMV8yNjI2MikiLz4KPGRlZnM+CjxyYWRpYWxHcmFkaWVudCBpZD0icGFpbnQwX2FuZ3VsYXJfMzMzMV8yNjI2MiIgY3g9IjAiIGN5PSIwIiByPSIxIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgZ3JhZGllbnRUcmFuc2Zvcm09InRyYW5zbGF0ZSgxMiAxMikgcm90YXRlKDkwKSBzY2FsZSgxMikiPgo8c3RvcCBzdG9wLWNvbG9yPSJ3aGl0ZSIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiNDNEM0QzQiIHN0b3Atb3BhY2l0eT0iMCIvPgo8L3JhZGlhbEdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=");
  background-size: contain;
}
.form__btn_main.done {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #624BED;
  background: #171525;
  box-shadow: 0px 2px 6px 0px rgba(19, 4, 105, 0.3);
}
.form__btn_main.done:after {
  content: "";
  position: relative;
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yMC43MDcxIDUuMjkyODlDMjEuMDk3NiA1LjY4MzQyIDIxLjA5NzYgNi4zMTY1OCAyMC43MDcxIDYuNzA3MTFMOS43MDcxMSAxNy43MDcxQzkuMzE2NTggMTguMDk3NiA4LjY4MzQyIDE4LjA5NzYgOC4yOTI4OSAxNy43MDcxTDMuMjkyODkgMTIuNzA3MUMyLjkwMjM3IDEyLjMxNjYgMi45MDIzNyAxMS42ODM0IDMuMjkyODkgMTEuMjkyOUMzLjY4MzQyIDEwLjkwMjQgNC4zMTY1OCAxMC45MDI0IDQuNzA3MTEgMTEuMjkyOUw5IDE1LjU4NThMMTkuMjkyOSA1LjI5Mjg5QzE5LjY4MzQgNC45MDIzNyAyMC4zMTY2IDQuOTAyMzcgMjAuNzA3MSA1LjI5Mjg5WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==");
  background-size: contain;
}
.form__block__row {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.form__block__title {
  width: 100%;
  max-width: 50%;
}
@media (max-width: 768px) {
  .form__block__title {
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .form__block {
    padding: 0 24px;
  }
}
.form__container {
  position: relative;
}
.form__input {
  border-radius: 7px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: #171525;
  color: #fff;
  width: 100%;
  padding: 16px 12px;
  outline: none;
  font-weight: 500;
}

form.modal-contactus__form {
  width: 100%;
}

.inputbase__valid {
  position: absolute;
}

.inputbase__content {
  display: none;
}

.modal-contactus__container {
  margin-top: 10px;
  margin-bottom: 10px;
}
.modal-contactus__container label.checkbox {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  width: fit-content;
  position: relative;
  cursor: pointer;
  user-select: none;
}
.modal-contactus__container a {
  color: #624bed;
}
.modal-contactus__container .checkbox__view {
  width: 16px;
  height: 16px;
  border: 1px solid #aabdee;
  border-radius: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.modal-contactus__container input.checkbox__input {
  margin-left: 0;
  display: none;
}
.modal-contactus__container input.checkbox__input:checked + .checkbox__view img {
  opacity: 1;
}
.modal-contactus__container .checkbox__view img {
  opacity: 0;
}

.form__btn_main.sending {
  color: transparent;
  animation: fadeInBtn 0.1s linear 1s forwards;
}
.form__btn_main.sending::before, .form__btn_main.sending::after {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  margin: auto;
  top: calc(50% - 12px);
  right: calc(50% - 12px);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 10;
}
.form__btn_main.sending::before {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../img/load.png);
  animation: rotateBefore 1s linear forwards;
}
.form__btn_main.sending::after {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../img/check.svg);
  opacity: 0;
  animation: fadeInAfter 1s linear 1s forwards;
}

@keyframes rotateBefore {
  from {
    transform: rotate(0deg);
    opacity: 1;
  }
  to {
    transform: rotate(360deg);
    opacity: 0;
  }
}
@keyframes fadeInAfter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeInBtn {
  to {
    background-color: transparent;
  }
}
.form__container_anim {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  gap: 3px;
  padding-left: 12px;
  line-height: 1;
  font-size: 14px;
  pointer-events: none;
}
.form__container_anim span {
  opacity: 0; /* Начальное состояние элемента - прозрачный */
  animation: fadeInOut 1.5s infinite;
  font-size: 15px;
}
.form__container_anim.is-active {
  display: none;
}

@keyframes fadeInOut {
  0%, 49.9% {
    opacity: 0;
  }
  50%, 100% {
    opacity: 1;
  }
}
.grid {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.grid__title {
  width: 100%;
  max-width: 75%;
}
@media (max-width: 768px) {
  .grid__title {
    max-width: 100%;
  }
}
.grid__row {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.grid__item {
  width: calc(50% - 4px);
  height: auto;
  border-radius: 10px;
  border: 1px solid rgba(172, 165, 225, 0.3019607843);
  background: radial-gradient(91.41% 81.02% at 50% 133.8%, rgba(255, 255, 255, 0.19) 0%, rgba(8, 6, 23, 0.15) 100%);
  backdrop-filter: blur(18px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.grid__item__info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 20px 12px 12px;
}
.grid__item__title {
  color: #FFF;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.07px;
}
.grid__item__text {
  color: #FFF;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 400;
}
.grid__item__img {
  width: 100%;
  object-fit: contain;
}
@media (max-width: 768px) {
  .grid {
    padding: 34px 24px;
  }
}

.header {
  width: 100%;
  max-width: 590px;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 32px;
}
.header__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__logo img {
  max-width: 200px;
  max-height: 38px;
  height: auto;
  object-fit: contain;
}
@media (max-width: 768px) {
  .header {
    padding: 24px;
  }
}

.info {
  padding: 75px 0 40px;
}
.info__card {
  border-radius: 10px;
  border: 1px solid #ACA5E1;
  background: radial-gradient(91.41% 81.02% at 50% 133.8%, rgba(255, 255, 255, 0.19) 0%, rgba(8, 6, 23, 0.15) 100%);
  backdrop-filter: blur(18px);
  width: 100%;
  min-width: 137px;
  height: 100%;
  max-height: 115px;
  padding: 16px 20px 16px 176px;
  position: relative;
}
@media (max-width: 768px) {
  .info__card {
    max-height: 100%;
  }
}
.info__block {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.info__title {
  font-weight: 600;
  letter-spacing: 0.07px;
}
.info__link {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.065px;
  color: #A2FACF;
}
.info__link:hover {
  color: #624bed;
}
.info__image {
  position: absolute;
  left: 0;
  top: -16px;
}
@media (max-width: 768px) {
  .info {
    padding: 75px 24px 0;
  }
}