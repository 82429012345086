.grid {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  gap: 32px;

  &__title {
    width: 100%;
    max-width: 75%;

    @media (max-width: 768px) {
      max-width: 100%;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__item {
    width: calc(50% - 4px);
    height: auto;
    border-radius: 10px;
    border: 1px solid #aca5e14d;
    background: radial-gradient(91.41% 81.02% at 50% 133.8%, rgba(255, 255, 255, 0.19) 0%, rgba(8, 6, 23, 0.15) 100%);
    backdrop-filter: blur(18px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__info {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 0 20px 12px 12px;
    }

    &__title {
      color: #FFF;
      font-family: $font;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.07px;
    }

    &__text {
      color: #FFF;
      font-family: $font;
      font-size: 13px;
      font-weight: 400;
    }

    &__img {
      width: 100%;
      object-fit: contain;
    }
  }

  @media (max-width: 768px) {
    padding: 34px 24px;
  }
}