
a {
    transition: all .4s ease;
}
button {
    cursor: pointer;
    transition: all .4s ease;
}

.banner {
    &__row {
        display: flex;
        flex-direction: column;
        gap: 43px;
    }

    &__title {
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 125%;

        a,
        span {
            color: #624BED;
            border-bottom: 1px solid;
            transition: .3s;

            &:hover {
                border-color: transparent;
            }
        }

        @media (max-width: 768px) {
            padding: 0 24px;
        }
    }
    
    &__form_w {
        @media (max-width: 768px){
            padding: 0 24px;
        }
    }

    &__phone {
        text-align: center;
        margin-bottom: -8px;
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: center;
        height: 465px;
        transform: scale(0.7);
        transform-origin: center bottom;
        margin-top: calc(40px - 30%);

        @media (max-width: 768px) {
            margin-top: -30%;
        }
        &:after {
            content: '';
            width: 313px;
            height: 313px;
            border-radius: 313px;
            opacity: 0.25;
            background: #8A88F6;
            filter: blur(50px);
            position: absolute;
            top: 76px;
            left: 23.5%;
            z-index: 0;
            display: block;
        }

        img {
            
            width: 350px;
        }
    }

    &__block {
        display: flex;
        flex-direction: column;
        gap: 0;

        &__link {
            border-radius: 60px;
            border: 1px solid #624BED;
            background: #5137EB;
            box-shadow: 0px 2px 6px 0px rgba(19, 4, 105, 0.30);
            width: 100%;
            max-width: 220px;
            padding-right: 24px;
            margin: 0 auto;
            position: absolute;
            z-index: 2;
            top: -19px;
            //left: 31.5%;
            min-height: 40px;
            max-height: 42px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 4px;
            transition: all .4s ease;
            // position: relative;
            a {
                position: absolute;
                right: 0;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                z-index: 10;
            }
            .svg_arr {
                transition: all .4s ease;
            }
            &:hover {

                border-color: #624BED;
                background: #624BED;
                
                .svg_arr {
                    transform: rotate(45deg);
                }
            }
            &__text {
                line-height: 1;
            }
            &__wrap {
                display: flex;
                justify-content: center;
                width: 100%;
            }

            &__img {
                position: absolute;
                left: -1px;
                top: -1px;

                svg {
                    box-shadow: 0px 7.143px 27.143px 0px rgba(38, 24, 121, 0.72);
                    border-radius: 99px;
                }
            }
        }

        &__wrapper {
            border-radius: 23px;
            border: 1px solid rgba(255, 255, 255, 0.13);
            background: #0D0A22;
            padding: 48px 24px 32px 24px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 32px;
            position: relative;
            z-index: 2;
        }

        &__text {
            p {
                margin-bottom: 12px;
            }
            &__wrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;
            }
        }

        .form__btn__tel {
            max-width: 444px;
        }
    }
}

.banner__phone_wr {
    position: relative;
    max-height: 455px;
    overflow: hidden;
}
.iframe-container {
    border-radius: 48px;
    display: block;
    position: absolute;
    width: 350px;
    height: 803px;
    z-index: 100;
    // top: -72px;
    // left: 78px;
    overflow:hidden;
    padding: 0 17px;
    top: 50px;
    &::before {
        content: '';
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        margin: 0 17px;
        height: 30px;
        background: transparent;
        background: -moz-linear-gradient(180deg, rgba(234,235,247,1) 0%, rgba(234,235,247,0) 100%);
        background: -webkit-linear-gradient(180deg, rgba(234,235,247,1) 0%, rgba(234,235,247,0) 100%);
        background: linear-gradient(180deg, rgba(234,235,247,1) 0%, rgba(234,235,247,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eaebf7",endColorstr="#eaebf7",GradientType=1);
    }
}
#iframe_connectme {

}

// @media screen and (max-width: 959px) {
//     .iframe-container {
//         transform: scale(0.625);
//         left: -118px;
//         top: -200px;
//         border-radius: 26px;
//     }
// }

// @media screen and (max-width: 639px) {
//     .iframe-container {
//         transform: scale(0.53);
//         left: -85px;
//         top: -170px;
//         border-radius: 26px;
//     }
// }

// @media screen and (max-width: 479px) {
//     .iframe-container {
//         transform: scale(0.27);
//         left: -132px;
//         top: -280px;
//         border-radius: 26px;
//     }
// }