.footer {
  position: relative;

  &__image {
    width: 100%;
    max-height: 557px;
    overflow: hidden;

    @media (max-width: 768px) {
      position: relative;
      height: 240px;
    }

    img {
      width: 100%;
      object-fit: contain;
      @media (max-width: 768px) {
        object-fit: cover;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        height: 100%;
      }
    }
  }

  &__logo {
    text-align: center;
    position: absolute;
    bottom: 46px;
    width: 100%;
    z-index: 10;
  }
}