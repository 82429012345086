.form {
  width: 100%;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
  }

  &__btn {
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.06);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    gap: 10px;
    color: #FFF;
    width: fit-content;

    &:hover {
      // background: rgba(255, 255, 255, 0.16);
      background: #624BED;
    }

    svg {
      max-width: 16px;
      max-height: 16px;
    }

    &__tel {
      width: calc(100% - 96px);
    }

    &_main {
      border: 1px solid #624BED;
      background: #5137EB;
      box-shadow: 0 2px 6px 0 rgba(19, 4, 105, 0.30);
      position: absolute;
      top: 0;
      right: 0;
      min-width: 80%;
      // transition: min-width 1.1s;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0;
      height: 100%;

      &.is-active {
        min-width: 136px;

        &:after {
          content: '';
          position: relative;
          width: 24px;
          height: 24px;
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImNoZXZyb24tcmlnaHQiPgo8cGF0aCBpZD0iVmVjdG9yIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTguMjkyODkgNS4yOTI4OUM4LjY4MzQyIDQuOTAyMzcgOS4zMTY1OCA0LjkwMjM3IDkuNzA3MTEgNS4yOTI4OUwxNS43MDcxIDExLjI5MjlDMTYuMDk3NiAxMS42ODM0IDE2LjA5NzYgMTIuMzE2NiAxNS43MDcxIDEyLjcwNzFMOS43MDcxMSAxOC43MDcxQzkuMzE2NTggMTkuMDk3NiA4LjY4MzQyIDE5LjA5NzYgOC4yOTI4OSAxOC43MDcxQzcuOTAyMzcgMTguMzE2NiA3LjkwMjM3IDE3LjY4MzQgOC4yOTI4OSAxNy4yOTI5TDEzLjU4NTggMTJMOC4yOTI4OSA2LjcwNzExQzcuOTAyMzcgNi4zMTY1OCA3LjkwMjM3IDUuNjgzNDIgOC4yOTI4OSA1LjI5Mjg5WiIgZmlsbD0id2hpdGUiLz4KPC9nPgo8L3N2Zz4K");
          background-size: contain;
        }
      }

      &.download {
        display: flex;
        justify-content: center;
        align-items: center;

        &:after {
          content: '';
          position: relative;
          width: 24px;
          height: 24px;
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI0IDEyQzI0IDE4LjYyNzQgMTguNjI3NCAyNCAxMiAyNEM1LjM3MjU4IDI0IDAgMTguNjI3NCAwIDEyQzAgNS4zNzI1OCA1LjM3MjU4IDAgMTIgMEMxOC42Mjc0IDAgMjQgNS4zNzI1OCAyNCAxMlpNMi40IDEyQzIuNCAxNy4zMDE5IDYuNjk4MDcgMjEuNiAxMiAyMS42QzE3LjMwMTkgMjEuNiAyMS42IDE3LjMwMTkgMjEuNiAxMkMyMS42IDYuNjk4MDcgMTcuMzAxOSAyLjQgMTIgMi40QzYuNjk4MDcgMi40IDIuNCA2LjY5ODA3IDIuNCAxMloiIGZpbGw9InVybCgjcGFpbnQwX2FuZ3VsYXJfMzMzMV8yNjI2MikiLz4KPGRlZnM+CjxyYWRpYWxHcmFkaWVudCBpZD0icGFpbnQwX2FuZ3VsYXJfMzMzMV8yNjI2MiIgY3g9IjAiIGN5PSIwIiByPSIxIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgZ3JhZGllbnRUcmFuc2Zvcm09InRyYW5zbGF0ZSgxMiAxMikgcm90YXRlKDkwKSBzY2FsZSgxMikiPgo8c3RvcCBzdG9wLWNvbG9yPSJ3aGl0ZSIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiNDNEM0QzQiIHN0b3Atb3BhY2l0eT0iMCIvPgo8L3JhZGlhbEdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=");
          background-size: contain;
        }
      }

      &.done {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #624BED;
        background: #171525;
        box-shadow: 0px 2px 6px 0px rgba(19, 4, 105, 0.30);

        &:after {
          content: '';
          position: relative;
          width: 24px;
          height: 24px;
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yMC43MDcxIDUuMjkyODlDMjEuMDk3NiA1LjY4MzQyIDIxLjA5NzYgNi4zMTY1OCAyMC43MDcxIDYuNzA3MTFMOS43MDcxMSAxNy43MDcxQzkuMzE2NTggMTguMDk3NiA4LjY4MzQyIDE4LjA5NzYgOC4yOTI4OSAxNy43MDcxTDMuMjkyODkgMTIuNzA3MUMyLjkwMjM3IDEyLjMxNjYgMi45MDIzNyAxMS42ODM0IDMuMjkyODkgMTEuMjkyOUMzLjY4MzQyIDEwLjkwMjQgNC4zMTY1OCAxMC45MDI0IDQuNzA3MTEgMTEuMjkyOUw5IDE1LjU4NThMMTkuMjkyOSA1LjI5Mjg5QzE5LjY4MzQgNC45MDIzNyAyMC4zMTY2IDQuOTAyMzcgMjAuNzA3MSA1LjI5Mjg5WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==");
          background-size: contain;
        }
      }
    }
  }

  &__block {
    &__row {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__title {
      width: 100%;
      max-width: 50%;

      @media (max-width: 768px) {
        max-width: 100%;
      }
    }

    @media (max-width: 768px) {
      padding: 0 24px;
    }
  }

  &__container {
    position: relative;
  }

  &__input {
    border-radius: 7px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: #171525;
    color: #fff;
    width: 100%;
    padding: 16px 12px;
    outline: none;
    font-weight: 500;
  }
}

form.modal-contactus__form {
  width: 100%;
}
.inputbase__valid {
  position: absolute;
}

.inputbase__content {
  display: none;
}
.modal-contactus__container {
  margin-top: 10px;
  margin-bottom: 10px;

  label.checkbox {
    display: flex;
    
    align-items: flex-start;
    gap: 8px;
    width: fit-content;
    position: relative;
    cursor: pointer;
    user-select: none;
  }

  a {
    color: #624bed;
  }
  .checkbox__view {
    width: 16px;
    height: 16px;
    border: 1px solid #aabdee;
    border-radius: 2px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

  }
  input.checkbox__input {
      margin-left: 0;
      display: none;

    &:checked+.checkbox__view img {
      opacity: 1;
    }

  }
      
  .checkbox__view img {
      opacity: 0;
  }
}

.form__btn_main.sending {
  color: transparent;
  animation: fadeInBtn 0.1s linear 1s forwards;
  
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    margin: auto;
    top: calc(50% - 12px);
    right: calc(50% - 12px);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 10;
  }
  &::before {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../img/load.png);
    animation: rotateBefore 1s linear forwards;
  }
  &::after {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../img/check.svg);
    opacity: 0;
    animation: fadeInAfter 1s linear 1s forwards;
  }
}

@keyframes rotateBefore {
  from {
    transform: rotate(0deg);
    opacity: 1;
  }
  to {
    transform: rotate(360deg);
    opacity: 0;
  }
}

@keyframes fadeInAfter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeInBtn {
  from {
    
  }
  to {
    background-color: transparent;
  }
}

.form__container_anim {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  gap: 3px;
  padding-left: 12px;
  line-height: 1;
  font-size: 14px;
  pointer-events: none;
  span {
    opacity: 0; /* Начальное состояние элемента - прозрачный */
    animation: fadeInOut 1.5s infinite;
    font-size: 15px;
  }

  &.is-active {
    display: none;
  }
}

@keyframes fadeInOut {
  // 0%, 100% { opacity: 0; }
  // 50% { opacity: 1; }
  0%, 49.9% { opacity: 0; }
  50%, 100% { opacity: 1; }
}
